<template>
  <div>
    <div class="card-tools-outlet">
      <div class="line">
        <div class="item">
          <a-button type="primary" @click="openModal">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            采购单
          </a-button>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <div class="label">筛选</div>
          <a-select v-model="listQuery.business_status" @change="getList">
            <a-select-option v-for="item in [{cat_name: '全部', cat_code: ''},...cat.buy_bstatus]" :key="item.cat_code" :value="item.cat_code">
              {{ item.cat_name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <div class="label">搜索</div>
          <a-input v-model="listQuery.keywords" placeholder="请输入产品名称" ><a-icon slot="prefix" type="search" /></a-input>
        </div>
        <a-button class="search" @click="getList">查询</a-button>
      </div>
    </div>
    <a-table :data-source="list" :pagination="pagination" @change="paginationChange" rowKey="oid">
      <a-table-column title="采购编号" width="363px">
        <template #default="row">
          <div class="td-line">
            <span class="title">{{ row.buyno }}</span>
            <a-tooltip>
              <template slot="title">修改</template>
<!--              <i class="iconfont icon-wenbenshuru" @click="editPurchase(row)"></i>-->
              <img :src="$toUrl('purchase_edit.png')" alt="" @click="editPurchase(row)">
            </a-tooltip>
            <a-tooltip>
              <template slot="title">删除</template>
              <img :src="$toUrl('delete3.svg')" alt="" @click="delPurchase(row)">
            </a-tooltip>
          </div>
        </template>
      </a-table-column>
      <a-table-column title="明细条数" data-index="list_count" width="150px"></a-table-column>
      <a-table-column title="项目编号" data-index="project_code" width="150px"></a-table-column>
      <a-table-column title="项目名称" data-index="project_name" min-width="355px"></a-table-column>
      <a-table-column title="产品编号" data-index="product_code" width="197px"></a-table-column>
      <a-table-column title="产品名称" data-index="product_name" width="353"></a-table-column>
      <a-table-column title="状态" width="136px">
        <template #default="row">
        <div :class="['edp-status', row.business_status === '0' ? 'blue' : 'green']"
            v-if="row">{{ row.status_name }}</div>
        </template>
      </a-table-column>
      <a-table-column title="更新时间" data-index="update_time" width="120px"></a-table-column>
    </a-table>
    <!-- 采购新增-->
    <create-purchase-modal ref="createPurchaseModal" :prj-oid="$route.query.prj_oid" @reload="getList"></create-purchase-modal>
    <!-- 采购详情-->
    <purchase-info-modal ref="purchaseInfoModal" :prj-oid="$route.query.prj_oid" @reload="getList"></purchase-info-modal>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin";
import catMixin from "@/utils/mixins/catMixin";
import createPurchaseModal from "@/components/createPurchaseModal.vue";
import purchaseInfoModal from "@/components/purchaseInfoModal.vue";
import {fetch} from "@/utils/request";

export default {
  name: "purchaseList",
  components: { createPurchaseModal, purchaseInfoModal },
  mixins: [listMixin, catMixin],
  data() {
    return{
      listMixinOptions: {
        url: "/whs/buy/retrieve"
      },
      page: {
        page_size: 15
      },
      listQuery: {
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        business_status: "",
      },
      cat: {
        buy_bstatus: []
      }
    }
  },
  methods: {
    openModal() {
      this.$refs.createPurchaseModal.open()
    },
    editPurchase(row) {
      this.$refs.purchaseInfoModal.open(row)
    },
    delPurchase(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/whs/buy/delete", {oid: row.oid}).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功！")
              this.getList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-table{
  .td-line {
    display: flex;
    align-items: center;
    .title {
      margin-right: 15px;
      white-space: nowrap;
    }
    i{
      font-size: 14px;
      line-height: 14px;
      height: 14px;
      margin-right: 10px;
      cursor: pointer;
      opacity: 0;
    }
    img {
      height: 14px;
      line-height: 14px;
      margin-right: 5px;
      cursor: pointer;
      opacity: 0;
    }

    &:hover{
      i,img{
        opacity: 1;
      }
    }
  }
}
</style>