<template>
  <div class="app-main-content">
    <card title="采购" :tabs="cat.buy_bstatus" v-model="business_status" body-class="page-card-body" @change="statusChange">
      <purchase-list ref="purchaseList"></purchase-list>
    </card>
  </div>
</template>

<script>
import purchaseList from "@/components/purchaseList.vue";
import catMixin from "@/utils/mixins/catMixin";
export default {
  mixins: [catMixin],
  data() {
    return {
      business_status: '',
      cat: {
        buy_bstatus: []
      }
    }
  },
  components: { purchaseList },
  methods: {
    statusChange(status) {
      console.log(status);
      this.$refs.purchaseList.listQuery.business_status = status
      this.$refs.purchaseList.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content{
  /deep/ .page-card-body {
    padding: 0 20px;
  }
}
</style>
