<template>
  <a-modal v-model="visible" width="1048px" class="purchaseInfoModal">
    <template #title>
      <div class="modal-header">
        <div class="title">采购详情</div>
        <div class="right-btn">
          <a-dropdown :trigger="['click']" placement="bottomCenter">
            <div class="right-btn-dropdown">
              <img :src="$toUrl('purchase_ellipsis.png')" alt="">
              <span>更多操作</span>
            </div>
            <a-menu slot="overlay" @click="selectActionMenu">
              <a-menu-item key="download">
                <div class="dropdown-item">
                  <img :src="$toUrl('purchase_download.png')" alt="">
                  <span>下载模板</span>
                </div>
              </a-menu-item>
              <a-menu-item key="import">
                <div class="dropdown-item">
                  <img :src="$toUrl('purchase_import.png')" alt="">
                  <span>导入</span>
                </div>
              </a-menu-item>
              <a-menu-item key="export">
                <div class="dropdown-item">
                  <img :src="$toUrl('purchase_export.png')" alt="">
                  <span>导出</span>
                </div>
              </a-menu-item>
              <a-menu-item key="print">
                <div class="dropdown-item">
                  <img :src="$toUrl('purchase_print.png')" alt="">
                  <span>打印</span>
                </div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="modal-body-left">
        <div class="head">
          <div class="head-title">基本信息</div>
          <div class="head-items">
            <div class="head-item row">
              <div class="label">采购编号</div>
              <div class="value">
                <edp-input
                    style="width: 270px"
                    v-model="form.buyno"
                    :db="dbs.whsCore"
                    field-name="buyno"
                    :pro-oid="form.prooid"
                    :oid="form.oid"
                    :buy-core-oid="form.oid"
                    size="purchase-mini"
                    @change="()=>{$emit('reload');refreshBizlog();}"
                    placeholder="请输入采购编号"
                    />
              </div>
            </div>
            <div class="head-item">
              <div class="label">项目名称</div>
              <div class="value">
                <edp-select
                    v-show="false"
                    ref="purchasePrjoid"
                    size="mini"
                    v-model="form.prjoid"
                    :db="dbs.whsCore"
                    field-name="prooid"
                    :pro-oid="form.prjoid"
                    :oid="form.oid"
                    :buy-core-oid="form.oid"
                    :options="prjOptions"
                    @change="()=>{$emit('reload');refreshBizlog();}"
                >
                </edp-select>
                <auto-input
                    v-if="!prjOid"
                    style="width: 100%"
                    class="onefield"
                    url="/prj/retrieve"
                    value-key="project_name"
                    label-key="project_name"
                    v-model="form.project_name"
                    :label.sync="form.project_name"
                    @change="changeProject"
                    placeholder="请输入项目名称"
                ></auto-input>
                <div class="a-style-ellipsis" v-else>{{ form.project_name }}</div>
              </div>
            </div>
            <div class="head-item">
              <div class="label">项目编号</div>
              <div class="value">{{ form.project_code }}</div>
            </div>
            <div class="head-item">
              <div class="label">产品名称</div>
              <div class="value">
                <edp-select
                    v-show="false"
                    ref="purchaseProoid"
                    size="mini"
                    v-model="form.prooid"
                    :db="dbs.whsCore"
                    field-name="prooid"
                    :pro-oid="form.prooid"
                    :oid="form.oid"
                    :buy-core-oid="form.oid"
                    :options="proOptions"
                    @change="()=>{$emit('reload');refreshBizlog();}"
                >
                </edp-select>
                <auto-input
                    style="width: 100%"
                    class="onefield"
                    url="/pro/retrieve"
                    value-key="product_name"
                    label-key="product_name"
                    v-model="form.product_name"
                    :label.sync="form.product_name"
                    @change="changeProduct"
                    placeholder="请输入产品名称"
                ></auto-input>
              </div>
            </div>
            <div class="head-item">
              <div class="label">产品编号</div>
              <div class="value">{{ form.product_code }}</div>
            </div>
            <div class="head-item">
              <div class="label">创建人</div>
              <div class="value">{{ form.creator_name }}</div>
            </div>
            <div class="head-item">
              <div class="label">当前状态</div>
              <div class="value">
                <edp-select
                    size="mini"
                    v-model="form.business_status"
                    :db="dbs.whsCore"
                    field-name="business_status"
                    :oid="form.oid"
                    :prj-oid="form.prjoid"
                    :buy-core-oid="form.oid"
                    :options="cat.buy_bstatus"
                    options-type="cat"
                    @change="()=>{$emit('reload');refreshBizlog();}"
                    placeholder="请选择状态"
                >
                </edp-select>
              </div>
            </div>
          </div>
        </div>
        <div class="tab">
          <a-tabs v-model="leftTab">
            <a-tab-pane key="1" tab="采购详情"></a-tab-pane>
            <a-tab-pane key="2" tab="入库详情"></a-tab-pane>
          </a-tabs>
        </div>
        <template v-if="leftTab === '1'">
          <div class="purchase-details">
            <div class="title d-flex j-c-space-b">
              <div>采购明细</div>
              <div class="right-btn" @click="addPurchase">
                <img :src="$toUrl('purchase_add.png')" alt="">
                <div>新增明细</div>
              </div>
            </div>
            <div class="purchase-table">
              <a-table :data-source="list" :pagination="false" rowKey="oid">
                <a-table-column title="规格型号" width="125px">
                  <template #default="row">
                    <div>
                      {{ row.tech_info }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="名称" width="165px">
                  <template #default="row">
                    <div>
                      <edp-select
                          v-show="false"
                          :ref="row.oid"
                          size="mini"
                          v-model="row.prooid"
                          :db="dbs.whsCoreList"
                          field-name="prooid"
                          :pro-oid="row.prooid"
                          :oid="row.oid"
                          :buy-core-oid="row.buycore_oid"
                          :options="proOptions"
                          @change="()=>{$emit('reload');refreshBizlog();}"
                      >
                      </edp-select>
                      <auto-input
                          style="width: 100%"
                          :class="[row.oid ? 'onefield' : 'table-select']"
                          url="/pro/retrieve"
                          :idx="row"
                          value-key="product_name"
                          label-key="product_name"
                          v-model="row.product_name"
                          :label.sync="row.product_name"
                          @change="sensingProduct"
                          placeholder="请输入名称"
                      ></auto-input>
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="数量" width="52px">
                  <template #default="row">
                    <div>
                      <edp-input
                          v-if="row.oid"
                          v-model="row.plan_count"
                          :db="dbs.whsCoreList"
                          field-name="plan_count"
                          :pro-oid="row.prooid"
                          :oid="row.oid"
                          :buy-core-oid="row.buycore_oid"
                          size="purchase-mini"
                          @change="()=>{$emit('reload');refreshBizlog();}"
                      >
                      </edp-input>
                      <a-input v-else size="small" v-model="row.plan_count"></a-input>
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="单位" width="52px">
                  <template #default="row">
                    <div>
                      {{ row.unit }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="期望交货期" width="97px">
                  <template #default="row">
                    <div>
                      <edp-input
                          v-if="row.oid"
                          v-model="row.plan_enddate"
                          :db="dbs.whsCoreList"
                          field-name="plan_enddate"
                          :pro-oid="row.prooid"
                          :oid="row.oid"
                          :buy-core-oid="row.buycore_oid"
                          size="purchase-mini"
                          @change="()=>{$emit('reload');refreshBizlog();}"
                      >
                      </edp-input>
                      <a-input v-else size="small" v-model="row.plan_enddate"></a-input>
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="品牌" width="78px">
                  <template #default="row">
                    <div>
                      {{ row.band }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="备注">
                  <template #default="row">
                    <div>
                      <edp-input
                          v-if="row.oid"
                          v-model="row.remark"
                          :db="dbs.whsCoreList"
                          field-name="remark"
                          :pro-oid="row.prooid"
                          :oid="row.oid"
                          :buy-core-oid="row.buycore_oid"
                          size="purchase-mini"
                          @change="()=>{$emit('reload');refreshBizlog();}"
                      >
                      </edp-input>
                      <a-input v-else size="small" v-model="row.remark"></a-input>
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="操作" width="35px">
                  <template #default="_, row, $index">
                    <div class="operate d-flex">
                      <img v-if="!row.oid" style="margin-right: 5px" :src="$toUrl('purchase_save.png')" alt="" @click="savePurchase(row, $index)">
                      <img :src="$toUrl('delete3.svg')" alt="" @click="delPurchase(row, $index)">
                    </div>
                  </template>
                </a-table-column>
              </a-table>
            </div>
            <div class="title">
              <div>外购联系信息</div>
            </div>
            <div class="items">
              <div class="item">
                <div class="label">到货地点</div>
                <div class="value">
                  <edp-input
                      v-model="form.accept_address"
                      :db="dbs.whsCore"
                      field-name="accept_address"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请输入到货地点"
                  />
                </div>
              </div>
              <div class="item">
                <div class="label">到货时间</div>
                <div class="value">
                  <edp-date-picker
                      v-model="form.accept_time"
                      :db="dbs.whsCore"
                      field-name="accept_time"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请选择到货时间"
                  >
                  </edp-date-picker>
<!--                  <edp-input-->
<!--                      v-model="form.accept_time"-->
<!--                      :db="dbs.whsCore"-->
<!--                      field-name="accept_time"-->
<!--                      :pro-oid="form.prooid"-->
<!--                      :oid="form.oid"-->
<!--                      :buy-core-oid="form.oid"-->
<!--                      size="purchase-mini"-->
<!--                      @change="()=>{$emit('reload');refreshBizlog();}"-->
<!--                  />-->
                </div>
              </div>
              <div class="item">
                <div class="label">接货人姓名</div>
                <div class="value">
                  <edp-input
                      v-model="form.acceptor"
                      :db="dbs.whsCore"
                      field-name="acceptor"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请输入接货人姓名"
                  />
                </div>
              </div>
              <div class="item">
                <div class="label">电话</div>
                <div class="value">
                  <edp-input
                      v-model="form.acceptor_tel"
                      :db="dbs.whsCore"
                      field-name="acceptor_tel"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请输入电话"
                  />
                </div>
              </div>
              <div class="item row">
                <div class="label">用途</div>
                <div class="value">
                  <edp-input
                      style="width: 270px"
                      v-model="form.buyobject"
                      :db="dbs.whsCore"
                      field-name="buyobject"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请输入用途"
                  />
                </div>
              </div>
              <div class="item row">
                <div class="label">其他</div>
                <div class="value">
                  <edp-input
                      style="width: 270px"
                      v-model="form.remark"
                      :db="dbs.whsCore"
                      field-name="remark"
                      :pro-oid="form.prooid"
                      :oid="form.oid"
                      :buy-core-oid="form.oid"
                      size="purchase-mini"
                      @change="()=>{$emit('reload');refreshBizlog();}"
                      placeholder="请输入其他"
                  />
                </div>
              </div>
            </div>
          </div>
          <attachment-upload :source-oid="form.oid" :prj-oid="prjOid" source-type="7" @setOid="oid => form.oid = oid" @change="refreshBizlog">
            <template slot="title">
              附件上传
            </template>
          </attachment-upload>
        </template>
        <template v-else>
          <div class="storage-details">
            <div class="storage-table">
              <a-table :data-source="list" :pagination="false" rowKey="oid">
                <a-table-column title="入库数量" width="110px">
                  <template #default="row">
                    <div class="icon-group" @click.stop>
                      <edp-input
                          v-if="row.oid"
                          class="ant-input-sm"
                          style="width: 60px;margin-right: 2px;"
                          v-model="row.whs_incount"
                          :db="dbs.whsCoreList"
                          field-name="whs_incount"
                          :pro-oid="row.prooid"
                          :oid="row.oid"
                          :buy-core-oid="row.buycore_oid"
                          @change="()=>{$emit('reload');refreshBizlog();}"
                      ></edp-input>
                      <span class="label">{{row.whs_incount}}</span>
                      <img class="icon" v-if="Number(row.whs_incount) >= Number(row.plan_count) && row.whs_incount !== ''" :src="$toUrl('finish.png')" alt="">
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="名称" min-width="130px">
                  <template #default="row">
                    <div>
                      {{ row.product_name }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="规格型号" width="120px">
                  <template #default="row">
                    <div>
                      {{ row.tech_info }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="采购数量" width="80px">
                  <template #default="row">
                    <div>
                      {{ row.plan_count }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="单位" width="80px">
                  <template #default="row">
                    <div>
                      {{ row.unit }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="交货期" width="80px">
                  <template #default="row">
                    <div>
                      {{ row.plan_enddate }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="品牌" width="80px">
                  <template #default="row">
                    <div>
                      {{ row.band }}
                    </div>
                  </template>
                </a-table-column>
              </a-table>
            </div>
          </div>
        </template>
      </div>
      <div class="modal-body-right">
        <div class="title">动态</div>
        <div class="tab">
          <a-tabs v-model="rightTab">
            <a-tab-pane key="1" tab="详情"></a-tab-pane>
          </a-tabs>
          <div class="y-scroll">
            <div class="active-1" v-show="rightTab === '1'">
              <bizlog ref="bizlog" :source-oid="form.oid"></bizlog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="modal-footer">
      </div>
    </template>
    <import-file url="/whs/buylist/import" :query="{buycore_oid: form.oid}" ref="importData" @response="uploadResponse"></import-file>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload.vue";
import autoInput from "@/components/autoInput.vue";
import edpInput from "@/components/e-form/edp-input.vue";
import edpDatePicker from "@/components/e-form/edp-date-picker.vue";
import edpSelect from "@/components/e-form/edp-select.vue";
import importFile from "@/components/importFile.vue";
import bizlog from "@/components/bizlog.vue";
import {fetch} from "@/utils/request";
import catMixin from "@/utils/mixins/catMixin";
import {genGuid, getTemplate} from "@/utils/helpFunc";
import {FieldUpdateDb} from "@/utils/FieldUpdate";
export default {
  name: "purchaseInfoModal",
  props: {
    prjOid: {
      type: String
    }
  },
  mixins: [catMixin],
  data() {
    return {
      dbs: {
        whsCore: new FieldUpdateDb("/whs/update/onefield", "whs_buycore"),
        whsCoreList: new FieldUpdateDb("/whs/update/onefield", "whs_buycore_list"),
      },
      form: {
        oid: '',
        buyno: '',
        prjoid: '',
        prooid: '',
        business_status: '0',
        creator_name: '',
        buyobject: '',
        accept_address: '',
        accept_time: '',
        acceptor: '',
        acceptor_tel: '',
        remark: '',
        supplier_info: '',
        project_name: '',
        project_code: '',
        product_name: '',
        list: []
      },
      list: [],
      visible: false,
      backup: {},
      cat: {
        phase_status: [],
        buy_bstatus: []
      },
      phaseOwnerList: [],
      leftTab: '1',
      rightTab: '1',
      proOptions: [],
      prjOptions: []
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { attachmentUpload, autoInput, bizlog, edpInput, edpDatePicker, importFile, edpSelect },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  mounted() {
  },
  methods: {
    open(fill = {}) {
      this.visible = true
      this.form = {
        ...this.backup,
        ...fill
      }
      this.list = fill.list
      this.proOptions = fill.list.map(e=>{
        return {
          label: e.product_name,
          value: e.prooid
        }
      })
      if (this.form.prooid) this.proOptions.push({label: this.form.product_name, value: this.form.prooid})
      if (this.form.prjoid) this.prjOptions.push({label: this.form.project_name, value: this.form.prjoid})
      if (this.prjOid) this.getPrjDetail() // 如果是项目产品里面需要查询项目名称和编号
    },
    getPrjDetail() {
      fetch("post", "/prj/info", {
        oid: this.prjOid
      }).then(res => {
        if (res.status === 0) {
          let {project_name, project_code} = res.data
          this.form = {
            ...this.form,
            project_name,
            project_code
          }
        }
      })
    },
    changeProduct(_, row) {
      console.log(_, row);
      // 给单字段组件赋予oldValue日志展示
      this.form.product_code = row.product_code
      this.form.prooid = row.oid
      if (!this.proOptions.find(e=>{return e.value === row.oid})) { // 选中后看有没有 没有加入
        this.proOptions.push({label: row.product_name, value: row.oid})
        this.$refs.purchaseProoid.selectOptions = this.proOptions
      }
      this.$refs.purchaseProoid.setValue(row.oid)
    },
    changeProject(_, row) {
      this.form.project_code = row.project_code
      this.form.prjoid = row.oid
      if (!this.prjOptions.find(e=>{return e.value === row.oid})) { // 选中后看有没有 没有加入
        this.prjOptions.push({label: row.project_name, value: row.oid})
        this.$refs.purchasePrjoid.selectOptions = this.prjOptions
      }
      this.$refs.purchasePrjoid.setValue(row.oid)
    },
    addPurchase() {
      this.list.push({
        id: genGuid(10),
        oid: "",
        buycore_oid: this.form.oid,
        tech_info: "",
        prooid: "",
        product_name: "",
        plan_count: "0",
        unit: "",
        plan_enddate: "",
        band: "",
        remark: "",
        token: "1",
        whs_incount: ""
      })
    },
    savePurchase(row, index) {
      console.log(row);
      fetch("post", "/whs/buylist/create", row).then(res => {
        if (res.status === 0) {
          console.log(res.data);
          let obj = {
            ...row,
            oid: res.data.oid,
            prooid: res.data.prooid
          }
          this.$set(this.list, index, obj)
        }
      })
    },
    delPurchase(row, index) {
      console.log(row, index);
      if (row.oid) {
        this.$confirm({
          title: '确定要删除吗?',
          onOk: () => {
            return fetch("post", "/whs/buylist/delete", {oid: row.oid}).then(res => {
              if (res.status === 0) {
                this.$message.success("删除成功！")
                this.list.splice(index, 1)
              } else {
                this.$message.error(res.message)
              }
            })
          },
          onCancel() {},
        });
      } else {
        this.list.splice(index, 1)
      }
    },
    sensingProduct(value, item, data) {
      console.log(value, item, data);
      this.$set(data, 'tech_info', item.tech_info)
      this.$set(data, 'band', item.band)
      this.$set(data, 'unit', item.unit)
      this.$set(data, 'prooid', item.oid)
      if (!this.proOptions.find(e=>{return e.value === item.oid})) { // 选中后看有没有 没有加入
        this.proOptions.push({label: item.product_name, value: item.oid})
        this.$refs[data.oid].selectOptions = this.proOptions
      }
      if (data.oid) {
        this.$refs[data.oid].setValue(item.oid)
      }
    },
    refreshBizlog() {
      this.$refs.bizlog.getBizLogList()
    },
    selectActionMenu({key}) {
      console.log(key);
      if (key === 'download') {
        getTemplate('/attachment/model', '采购单导入模版.xlsx')
      } else if (key === 'import') {
        this.$refs.importData.upload()
      } else if (key === 'export') {
        window.open(this.$toUrl(`/whs/buy/export?oid=${this.form.oid}&print=0`, true, "api"))
      } else if (key === 'print') {
        window.open(this.$toUrl(`/whs/buy/export?oid=${this.form.oid}&print=1`, true, "api"))
      }
    },
    getPurchaseList(){
      fetch("post", "/whs/buylist/retrieve", {
        buycore_oid: this.form.oid
      }).then(res => {
        if (res.status === 0) {
          this.list = res.data.datas
        }
      })
    },
    // 导入模板的回调
    uploadResponse(res) {
      if (res.status === 0) {
        this.getPurchaseList()
        this.$emit('reload')
      } else {
        this.$message.error(res.message)
      }
    },
  }
}
</script>

<style scoped lang="less">
.purchaseInfoModal {
  /deep/ .edp-input-size-purchase-mini {
    width: 100%;
    --height: 28px;
  }
}
/deep/ .ant-modal-header{
  padding: 16px 20px;
}

/deep/ .ant-modal-body{
  padding: 0;
  .modal-body{
    display: flex;
    height: 700px;
    overflow: hidden;

    .modal-body-left{
      width: 710px;
      overflow-y: auto;

      .head{
        padding: 20px;
        border-bottom: 1px solid #e8e8e8;
        .head-title{
          font-size: 16px;
          color: #000000;
          line-height: 21px;
          height: 21px;
          margin-bottom: 13px;
        }
        .head-items{
          display: flex;
          flex-wrap: wrap;

          .head-item{
            display: flex;
            align-items: center;
            font-weight: 400;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #000000;
            width: 50%;
            margin-bottom: 4px;

            &:nth-last-child(1),&:nth-last-child(2){
              margin-bottom: 0;
            }

            &.row{
              width: 100%;
            }

            .label{
              width: 117px;
              flex-shrink: 0;
            }

            .value{
              flex: 1;
              overflow: hidden;
              padding-right: 10px;
            }
          }
        }
      }
      .tab{
        .ant-tabs{
          .ant-tabs-bar{
            margin-bottom: 0;
            .ant-tabs-tab{
              padding: 15px 0;
              margin-right: 37px;
              &:first-child{
                margin-left: 20px;
              }
            }
          }
        }
      }
      .purchase-details{
        padding: 20px;
        .title{
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          line-height: 16px;
          height: 16px;
          margin-bottom: 13px;

          .right-btn{
            display: flex;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            cursor: pointer;

            >img{
              width: 16px;
              height: 16px;
              margin-right: 6px;
            }
          }
        }

        .purchase-table{
          margin-bottom: 20px;

          .ant-table{
            .ant-table-body{
              table{
                border-color: transparent;
                .ant-table-thead{
                  tr {
                    th{
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 14px;
                      color: #000000;
                      padding: 10px 0 10px;
                      //background-color: transparent;
                      border-color: transparent;

                      &:nth-of-type(1){
                        padding: 10px 0 10px 20px;
                      }
                    }
                  }
                }
                .ant-table-tbody{
                  tr{
                    td{
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 12px;
                      color: #000000;
                      padding: 5px 5px 5px 0;
                      border-color: transparent;
                      border-bottom: 1px solid #EDEDED;
                      &:nth-of-type(1) {
                        padding-left: 20px;
                      }
                    }
                    &:nth-of-type(1) td {
                      border-top: 1px solid #EDEDED;
                    }
                  }
                }

                .operate{
                  >i{
                    cursor: pointer;
                  }
                  >img{
                    width: 12px;
                    height: 12px;
                    display: block;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .items{
          display: flex;
          flex-wrap: wrap;

          .item{
            display: flex;
            font-weight: 400;
            font-size: 14px;
            height: 28px;
            line-height: 28px;
            color: #000000;
            width: 50%;
            margin-bottom: 4px;

            &:nth-last-child(1){
              margin-bottom: 0;
            }

            &.row{
              width: 100%;
            }

            .label{
              width: 117px;
            }

            .value{
              flex: 1;
              padding-right: 10px;
            }
          }
        }
      }

      .storage-details{
        .storage-table{

          .icon-group {
            height: 28px;
            display: flex;
            align-items: center;
            &:hover, &:has(.ant-input:focus) {
              .edp-input {
                display: inline-block;
              }
              .label {
                display: none;
              }
            }
            .edp-input {
              display: none;
            }
            .label {
              margin-right: 5px;
            }
            .icon {
              width: 13px;
              height: 13px;
              margin-top: 1px;
            }
          }

          .ant-table{
            .ant-table-body{
              table{
                border-color: transparent;
                .ant-table-thead{
                  tr {
                    th{
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 14px;
                      color: #000000;
                      padding: 10px 0 10px;
                      //background-color: transparent;
                      border-color: transparent;

                      &:nth-of-type(1){
                        padding: 10px 0 10px 20px;
                      }
                    }
                  }
                }

                .ant-table-tbody{
                  tr{
                    td{
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 12px;
                      color: #000000;
                      padding: 5px 5px 5px 0;
                      border-color: transparent;
                      border-bottom: 1px solid #EDEDED;
                      &:nth-of-type(1) {
                        padding-left: 20px;
                      }
                    }
                    &:nth-of-type(1) td {
                      border-top: 1px solid #EDEDED;
                    }
                  }
                }

                .operate{
                  >i{
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal-body-right{
      flex: 1;
      border-left: 1px solid #e8e8e8;
      overflow-y: auto;

      .tab {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        .y-scroll {
          flex: 1;
          overflow-y: auto;

          .active-1 {
          }
        }
      }

      > .title {
        height: 45px;
        line-height: 50px;
        padding: 0 13px;
        font-size: 16px;
        font-weight: 500;
        color: #303030;
      }

      > .tab {
        .ant-tabs{
          .ant-tabs-bar{
            margin-bottom: 0;
            .ant-tabs-tab{
              padding: 12px 0;
              &:first-child{
                margin-left: 21px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .ant-dropdown-menu-item{
  .dropdown-item{
    display: flex;
    align-items: center;
    >img{
      width: 14px;
      height: 14px;
      display: block;
      margin-right: 6px;
    }
    >span{
      display: block;
      font-size: 14px;
      color: #494949;
      height: 14px;
      line-height: 14px;
    }
  }
}


/deep/ .ant-modal-header{
  .modal-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 38px;

    .title{
      font-size: 18px;
    }

    .right-btn{
      .right-btn-dropdown{
        display: flex;
        align-items: center;
        cursor: pointer;
        >img{
          display: block;
          width: 14px;
          height: 14px;
          margin-right: 7px;
          object-fit: contain;
        }
        >span{
          display: block;
          font-size: 14px;
          color: #494949;
          height: 14px;
          line-height: 14px;
        }
      }
    }
  }
}

/deep/ .ant-modal-footer{
  border: none;
  padding: 0;
}

/deep/ .ant-input-sm{
  height: 28px;
  color: #000;
}

/deep/ .ant-select:not(.select) {
  height: 28px;
  line-height: 28px;
  &.table-select{
    .ant-input{
      padding: 1px 7px
    }
  }
  .ant-select-selection:not(.ant-select-selection--multiple){
    height: 28px;
    line-height: 28px;
    .ant-select-selection__rendered{
      line-height: 28px;
    }
    .ant-input{
      height: 28px;
      color: #000;
    }
  }
}

/deep/ .attachment-upload{
  .content{
    .item{
      padding: 0 20px 15px;
    }
  }
}

</style>
