<template>
  <a-modal v-model="visible" title="新增采购" width="715px" class="createPurchaseModal">
    <div class="modal-body">
      <div class="head">
        <div class="head-title">基本信息</div>
        <div class="head-items">
          <div class="head-item row">
            <div class="label">采购编号</div>
            <div class="value"><a-input style="width: 275px" placeholder="请输入采购编号" class="onefield" size="small" v-model="form.buyno"></a-input></div>
          </div>
          <div class="head-item">
            <div class="label">项目名称</div>
            <div class="value">
              <auto-input
                  v-if="!prjOid"
                  style="width: 100%"
                  class="onefield"
                  url="/prj/retrieve"
                  value-key="oid"
                  label-key="project_name"
                  v-model="form.prjoid"
                  :label.sync="form.project_name"
                  @change="changeProject"
                  placeholder="请输入项目名称"
              ></auto-input>
              <div class="a-style-ellipsis" v-else>{{ form.project_name }}</div>
            </div>
          </div>
          <div class="head-item">
            <div class="label">项目编号</div>
            <div class="value">{{ form.project_code }}</div>
          </div>
          <div class="head-item">
            <div class="label">产品名称</div>
            <div class="value">
              <auto-input
                style="width: 100%"
                class="onefield"
                url="/pro/retrieve"
                value-key="oid"
                label-key="product_name"
                v-model="form.prooid"
                :label.sync="form.product_name"
                @change="changeProduct"
                placeholder="请输入产品名称"
              ></auto-input>
            </div>
          </div>
          <div class="head-item">
            <div class="label">产品编号</div>
            <div class="value">{{ form.product_code }}</div>
          </div>
        </div>
      </div>
      <div class="purchase-details">
        <div class="title d-flex j-c-space-b">
          <div>采购明细</div>
          <div class="right-btn" @click="addPurchase">
            <img :src="$toUrl('purchase_add.png')" alt="">
            <div>新增明细</div>
          </div>
        </div>
        <div class="purchase-table">
          <a-table :data-source="list" :pagination="false" rowKey="id">
            <a-table-column title="规格型号" width="115px">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.tech_info"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="名称" width="176px">
              <template #default="row">
                <div>
                  <auto-input
                      style="width: 100%"
                      class="table-select"
                      url="/pro/retrieve"
                      :idx="row"
                      value-key="oid"
                      label-key="product_name"
                      v-model="row.prooid"
                      :label.sync="row.product_name"
                      @change="sensingProduct"
                      placeholder="请输入名称"
                  ></auto-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="数量" width="52px">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.plan_count"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="单位" width="52px">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.unit"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="期望交货期" width="97px">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.plan_enddate"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="品牌" width="78px">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.band"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="备注">
              <template #default="row">
                <div>
                  <a-input size="small" v-model="row.remark"></a-input>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="操作" width="35px">
              <template #default="_, row, $index">
                <div class="operate d-flex">
                  <a-icon type="delete" @click="delPurchase(row, $index)" />
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
        <div class="title">
          <div>外购联系信息</div>
        </div>
        <div class="items">
          <div class="item">
            <div class="label">到货地点</div>
            <div class="value"><a-input class="onefield" size="small" placeholder="请输入到货地点" v-model="form.accept_address"></a-input></div>
          </div>
          <div class="item">
            <div class="label">到货时间</div>
            <div class="value"><a-date-picker class="onefield" size="small" valueFormat="YYYY/MM/DD" placeholder="请选择到货时间" v-model="form.accept_time" style="width: 100%;"/></div>
          </div>
          <div class="item">
            <div class="label">接货人姓名</div>
            <div class="value"><a-input class="onefield" size="small" placeholder="请输入接货人姓名" v-model="form.acceptor"></a-input></div>
          </div>
          <div class="item">
            <div class="label">电话</div>
            <div class="value"><a-input class="onefield" size="small" placeholder="请输入电话" v-model="form.acceptor_tel"></a-input></div>
          </div>
          <div class="item row">
            <div class="label">用途</div>
            <div class="value"><a-input style="width: 275px" class="onefield" size="small" placeholder="请输入用途" v-model="form.buyobject"></a-input></div>
          </div>
          <div class="item row">
            <div class="label">其他</div>
            <div class="value"><a-input style="width: 275px" class="onefield" size="small" placeholder="请输入其他" v-model="form.remark"></a-input></div>
          </div>
        </div>
      </div>
    </div>
    <attachment-upload style="padding: 0 20px" :source-oid="form.oid" :prj-oid="prjOid" source-type="7" @setOid="oid => form.oid = oid">
      <template slot="title">
        附件上传
      </template>
    </attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-checkbox v-model="createNext">
          继续创建下一个
        </a-checkbox>
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload.vue";
import autoInput from "@/components/autoInput.vue";
import {fetch} from "@/utils/request";
import catMixin from "@/utils/mixins/catMixin";
import {genGuid} from "@/utils/helpFunc";
export default {
  name: "createPurchaseModal",
  props: {
    prjOid: {
      type: String
    }
  },
  mixins: [catMixin],
  data() {
    return {
      form: {
        oid: '',
        buyno: '',
        prjoid: '',
        prooid: '',
        business_status: '0',
        buyobject: '',
        accept_address: '',
        accept_time: '',
        acceptor: '',
        acceptor_tel: '',
        remark: '',
        supplier_info: '',
        project_name: '',
        project_code: '',
        product_name: '',
        list: []
      },
      list: [],
      visible: false,
      backup: {},
      cat: {
        phase_status: []
      },
      phaseOwnerList: [],
      createNext: false
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {attachmentUpload, autoInput},
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  mounted() {
  },
  methods: {
    open(fill = {}) {
      this.visible = true
      this.createNext = false
      this.list = []
      this.form = {
        ...this.backup,
        ...fill
      }
      if (this.prjOid) this.getPrjDetail() // 如果是项目产品里面需要查询项目名称和编号
    },
    submit() {
      fetch('post','/whs/buy/create', {
        ...this.form,
        list: this.list,
        prjoid: this.form.prjoid ? this.form.prjoid : this.prjOid
      }).then((res)=>{
        if (res.status === 0) {
          this.$message.success('新增成功！')
          if (this.createNext) {
            this.open({})
            this.list = []
            this.createNext = true
          } else {
            this.visible = false
          }
          this.$emit('reload')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getPrjDetail() {
      fetch("post", "/prj/info", {
        oid: this.prjOid
      }).then(res => {
        if (res.status === 0) {
          let {project_name, project_code} = res.data
          this.form = {
            ...this.form,
            project_name,
            project_code
          }
        }
      })
    },
    changeProduct(_, row) {
      this.form.product_code = row.product_code
    },
    changeProject(_, row) {
      this.form.project_code = row.project_code
    },
    addPurchase() {
      this.list.push({
        id: genGuid(10),
        buycore_oid: "",
        tech_info: "",
        prooid: "",
        product_name: "",
        plan_count: "",
        unit: "",
        plan_enddate: "",
        band: "",
        remark: "",
        token: "1"
      })
    },
    delPurchase(row, index) {
      // console.log(row, index);
      this.list.splice(index, 1)
    },
    sensingProduct(value, item, data) {
      // console.log(value, item, data);
      this.$set(data, 'tech_info', item.tech_info)
      this.$set(data, 'band', item.band)
      this.$set(data, 'unit', item.unit)
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-modal-header{
  padding: 16px 20px;
}

/deep/ .ant-modal-body{
  padding: 0;
  .modal-body{
    .head{
      padding: 20px;
      border-bottom: 1px solid #e8e8e8;
      .head-title{
        font-size: 16px;
        color: #000000;
        line-height: 21px;
        height: 21px;
        margin-bottom: 13px;
      }
      .head-items{
        display: flex;
        flex-wrap: wrap;

        .head-item{
          display: flex;
          align-items: center;
          font-weight: 400;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
          color: #000000;
          width: 50%;
          margin-bottom: 4px;

          &:nth-last-child(1),&:nth-last-child(2){
            margin-bottom: 0;
          }

          &.row{
            width: 100%;
          }

          .label{
            width: 117px;
            flex-shrink: 0;
          }

          .value{
            flex: 1;
            overflow: hidden;
            padding-right: 10px;
          }
        }
      }
    }
    .purchase-details{
      padding: 20px;
      .title{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        line-height: 16px;
        height: 16px;
        margin-bottom: 13px;

        .right-btn{
          display: flex;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          cursor: pointer;

          >img{
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
        }
      }

      .purchase-table{
        margin-bottom: 20px;

        .ant-table{
          .ant-table-body{
            table{
              border-color: transparent;
              .ant-table-thead{
                tr {
                  th{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: #000000;
                    padding: 10px 0 10px;
                    //background-color: transparent;
                    border-color: transparent;
                  }
                }
              }
              .ant-table-tbody{
                tr{
                  td{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    color: #000000;
                    padding: 5px 5px 5px 0;
                    border-color: transparent;
                    border-bottom: 1px solid #EDEDED;
                  }
                  &:nth-of-type(1) td {
                    border-top: 1px solid #EDEDED;
                  }
                }
              }

              .operate{
                >i{
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .items{
        display: flex;
        flex-wrap: wrap;

        .item{
          display: flex;
          font-weight: 400;
          font-size: 14px;
          height: 28px;
          line-height: 28px;
          color: #000000;
          width: 50%;
          margin-bottom: 4px;

          &:nth-last-child(1){
            margin-bottom: 0;
          }

          &.row{
            width: 100%;
          }

          .label{
            width: 117px;
          }

          .value{
            flex: 1;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

/deep/ .ant-modal-footer{
  border-color: transparent;
  .modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
}

/deep/ .ant-input-sm{
  height: 28px;
  color: #000;
}

/deep/ .ant-select:not(.select) {
  height: 28px;
  line-height: 28px;
  &.table-select{
    .ant-input{
      padding: 1px 7px
    }
  }
  .ant-select-selection:not(.ant-select-selection--multiple){
    height: 28px;
    line-height: 28px;
    .ant-select-selection__rendered{
      line-height: 28px;
    }
    .ant-input{
      height: 28px;
    }
  }
}

</style>
